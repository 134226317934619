export const getListings = `query GetListings($hostId: String!) {
  getListings(hostId: $hostId) {
    hostId
    propertyCode
    listingId
    listingName
    internalName
    needManualCheck
    guideUrl
    registeredOn
    pmsListingId
    pmsUserId
    active
    staticKeyItems {
      roomCode
      keyInfo
    }
    smartKeyItems {
      roomCode
      equipmentId
      equipmentName
    }
    checkInTime
    checkOutTime
    countryCode
    timezone
    lastUpdateOn
  }
}
`

export const createListing = `mutation CreateListing($input: CreateListingInput!) {
  createListing(input: $input) {
    hostId
    propertyCode
    listingId
    listingName
    internalName
    needManualCheck
    guideUrl
    registeredOn
    pmsListingId
    pmsUserId
    active
    staticKeyItems {
      roomCode
      keyInfo
    }
    smartKeyItems {
      roomCode
      equipmentId
      equipmentName
    }
    checkInTime
    checkOutTime
    countryCode
    timezone
    lastUpdateOn
  }
}
`

export const updateListing = `mutation UpdateListing($input: UpdateListingInput!, $expectedLastUpdateOn: AWSDateTime!) {
  updateListing(input: $input, expectedLastUpdateOn: $expectedLastUpdateOn) {
    hostId
    propertyCode
    listingId
    listingName
    internalName
    needManualCheck
    guideUrl
    registeredOn
    pmsListingId
    pmsUserId
    active
    staticKeyItems {
      roomCode
      keyInfo
    }
    smartKeyItems {
      roomCode
      equipmentId
      equipmentName
    }
    checkInTime
    checkOutTime
    countryCode
    timezone
    lastUpdateOn
  }
}
`

export const importListings = `mutation ImportListings($input: UpdateHostPmsInput!) {
  importListings(input: $input) {
    listingName
    internalName
    guideUrl
    registeredOn
    pmsListingId
    pmsUserId
    needManualCheck
    keyInfoMessage
    reminderMessage
    pendingMessage
    active
    lastUpdateOn
  }
}
`

export const getReservations = `query GetReservations($listingId: String!) {
  getReservations(listingId: $listingId) {
    reservationCode
    listingId
    hostId
    username
    email
    phoneNo
    bookingId
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    notificationResponses {
      result
      messageType
      messageDate
      errorCode
      error
    }
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const getReservation = `query GetReservation($reservationCode: String!, $listingId: String!) {
  getReservation(reservationCode: $reservationCode, listingId: $listingId) {
    reservationCode
    listingId
    hostId
    username
    email
    phoneNo
    bookingId
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    notificationResponses {
      result
      messageType
      messageDate
      errorCode
      error
    }
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const notifyGuest = `mutation NotifyGuest($input: MutateReservationInput!, $messageType: String!) {
  notifyGuest(input: $input, messageType: $messageType) {
    hostId
    listingId
    reservationCode
    bookingId
    phoneNo
    username
    email
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    notificationResponses {
      result
      messageType
      messageDate
      errorCode
      error
    }
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const updateReservationStatus = `mutation UpdateReservationStatus($input: MutateReservationInput!, $expectedLastUpdateOn: AWSDateTime!) {
  updateReservationStatus(input: $input, expectedLastUpdateOn: $expectedLastUpdateOn) {
    hostId
    listingId
    reservationCode
    bookingId
    phoneNo
    username
    email
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    notificationResponses {
      result
      messageType
      messageDate
      errorCode
      error
    }
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`


export const onUpdateReservation = `subscription OnUpdateReservation($hostId: String!, $listingId: String!) {
  onUpdateReservation(hostId: $hostId, listingId: $listingId)
  {
    reservationCode
    listingId
    hostId
    username
    email
    phoneNo
    bookingId
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    notificationResponses {
      result
      messageType
      messageDate
      errorCode
      error
    }
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const removeReservation = `mutation RemoveReservation($input: MutateReservationInput!) {
  removeReservation(input: $input)
  {
    hostId
    listingId
    reservationCode
  }
}
`


export const onRemoveReservation = `subscription OnRemoveReservation($hostId: String!) {
  onRemoveReservation(hostId: $hostId)
  {
    hostId
    listingId
    reservationCode
    bookingId
    phoneNo
    username
    email
    guestName
    referrer
    lastUpdateOn
  }
}
`

export const generateReservation = `mutation GenerateReservation($input: MutateReservationInput!) {
  generateReservation(input: $input)
  {
    hostId
    listingId
    reservationCode
    bookingId
    phoneNo
    username
    email
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    notificationResponses {
      result
      messageType
      messageDate
      errorCode
      error
    }
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const updateIdVerified = `mutation UpdateIdVerified($input: MutateReservationInput!) {
  updateIdVerified(input: $input)
  {
    hostId
    listingId
    reservationCode
    bookingId
    phoneNo
    username
    email
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    notificationResponses {
      result
      messageType
      messageDate
      errorCode
      error
    }
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

// export const verifyMembers = `mutation VerifyMembers($input: MutateReservationInput!, $hostVerifying: Boolean!) {
//   verifyMembers(input: $input, hostVerifying: $hostVerifying)
//   {
//     hostId
//     listingId
//     reservationCode
//     bookingId
//     phoneNo
//     username
//     email
//     guestName
//     identityId
//     memberCount
//     checkInDate
//     checkOutDate
//     smartKeyItems {
//       roomCode
//       keyInfo
//       equipmentId
//     }
//     isKeyNotified
//     isIdVerified
//     lastRequestOn
//     lastResponse
//     rejectReason
//     notificationResponses {
//       result
//       messageType
//       messageDate
//       errorCode
//       error
//     }
//     referrer
//     lastUpdateOn
//   }
// }
// `

export const getMembers = `query GetMembers($reservationCode: String!) {
  getMembers(reservationCode: $reservationCode) {
    reservationCode
    memberNo
    imgBucket
    faceImgName
    faceImgKey
    idImgName
    idImgKey
    similarity
    idMatches
    idVerified
    fullName
    gender
    birthDate
    address
    phoneNumber
    idNumber
    nationality
    occupation
    prevPlace
    nextDest
    memberKeyItem {
      roomCode
      keyInfo
    }
    lastUpdateOn
  }
}
`

export const createMember = `mutation CreateMember($input: CreateMemberInput!) {
  createMember(input: $input) {
    reservationCode
    memberNo
    imgBucket
    faceImgName
    faceImgKey
    idImgName
    idImgKey
    similarity
    idMatches
    idVerified
    fullName
    gender
    birthDate
    address
    phoneNumber
    idNumber
    nationality
    occupation
    prevPlace
    nextDest
    memberKeyItem {
      roomCode
      keyInfo
    }
    lastUpdateOn
  }
}
`

export const updateMember = `mutation UpdateMember($input: UpdateMemberInput!, $expectedLastUpdateOn: AWSDateTime!) {
  updateMember(input: $input, expectedLastUpdateOn: $expectedLastUpdateOn)
  {
    reservationCode
    memberNo
    imgBucket
    faceImgName
    faceImgKey
    idImgName
    idImgKey
    similarity
    idMatches
    idVerified
    fullName
    gender
    birthDate
    address
    phoneNumber
    idNumber
    nationality
    occupation
    prevPlace
    nextDest
    memberKeyItem {
      roomCode
      keyInfo
    }
    lastUpdateOn
  }
}
`

// export const onCreateNotification = `subscription OnCreateNotification($hostId: String!) {
//   onCreateNotification(hostId: $hostId)
//   {
//     hostId
//     reservationCode
//     listingId
//     bookingId
//     identityId
//     phoneNo
//     guestName
//     memberCount
//     checkInDate
//     checkOutDate
//     lastUpdateOn
//   }
// }
// `


// const createNotification = `mutation CreateNotification($input: CreateNotificationInput!) {
//   createNotification(input: $input) {
//     hostId
//     reservationCode
//     listingId
//     bookingId
//     identityId
//     phoneNo
//     guestName
//     memberCount
//     checkInDate
//     checkOutDate
//     lastUpdateOn
//   }
// }
// `

// export const getNotifications = `query GetNotifications($hostId: String!) {
//   getNotifications(hostId: $hostId) {
//     hostId
//     reservationCode
//     listingId
//     bookingId
//     identityId
//     phoneNo
//     guestName
//     memberCount
//     checkInDate
//     checkOutDate
//     lastUpdateOn
//   }
// }
// `

// export const deleteNotification = `mutation DeleteNotification($input: DeleteNotificationInput!) {
//   deleteNotification(input: $input) {
//     hostId
//     reservationCode
//     listingId
//     bookingId
//     identityId
//     phoneNo
//     guestName
//     memberCount
//     checkInDate
//     checkOutDate
//   }
// }
// `

export const getScanners = `query GetScanners($hostPropertyCode: String!) {
  getScanners(hostPropertyCode: $hostPropertyCode) {
    propertyCode
    roomCode
  }
}
`


export const onCreateScanner = `subscription OnCreateScanner($hostId: String!) {
  onCreateScanner(hostId: $hostId)
  {
    uuid
    hostId
    propertyCode
    equipmentId
    equipmentName
    coreName
    localIp
    latitude
    longitude
    roomCode
    lastUpdateOn
  }
}
`

export const getScanRecords = `query GetScanRecords($terminalKey: String!) {
  getScanRecords(terminalKey: $terminalKey) {
    terminalKey
    recordTime
    hostId
    reservationCode
    userName
    temperature
    mask
  }
}
`

export const onCreateScanRecord = `subscription OnCreateScanRecord($hostId: String!, $terminalKey: String!) {
  onCreateScanRecord(hostId: $hostId, terminalKey: $terminalKey)
  {
    terminalKey
    recordTime
    hostId
    reservationCode
    userName
    temperature
    mask
  }
}
`

export const getCalendars = `query GetCalendars($listingId: String!, $checkInDate: String!, $checkOutDate: String!, $oldCheckInDate: String, $oldCheckOutDate: String) {
  getCalendars(listingId: $listingId, checkInDate: $checkInDate, checkOutDate: $checkOutDate, oldCheckInDate: $oldCheckInDate, oldCheckOutDate: $oldCheckOutDate) {
    reservedDate
  }
}
`

export const getHost = `query GetHost($hostId: String!) {
  getHost(hostId: $hostId) {
    hostId
    identityId
    email
    smartlockProperty {
      smartlockVerified
      smartlockHost
      smartlockAuth
      resourceServerIdentifier
      clientId
      clientSecret
    }
    pmsUserId
    lastUpdateOn
  }
}
`

export const createHost = `mutation CreateHost($input: CreateHostInput!) {
  createHost(input: $input) {
    hostId
    identityId
    email
    smartlockProperty {
      smartlockVerified
      smartlockHost
      smartlockAuth
      resourceServerIdentifier
      clientId
      clientSecret
    }
    pmsUserId
    lastUpdateOn
  }
}
`

export const updateHost = `mutation UpdateHost($input: UpdateHostInput!, $expectedLastUpdateOn: AWSDateTime!) {
  updateHost(input: $input, expectedLastUpdateOn: $expectedLastUpdateOn) {
    hostId
    identityId
    email
    smartlockProperty {
      smartlockVerified
      smartlockHost
      smartlockAuth
      resourceServerIdentifier
      clientId
      clientSecret
    }
    pmsUserId
    lastUpdateOn
  }
}
`

export const verifyChannel = `mutation VerifyChannel($hostId: String!, $inviteCode: String!) {
  verifyChannel(hostId: $hostId, inviteCode: $inviteCode) {
    hostId
    identityId
    email
    smartlockProperty {
      smartlockVerified
      smartlockHost
      smartlockAuth
      resourceServerIdentifier
      clientId
      clientSecret
    }
    pmsUserId
    lastUpdateOn
  }
}
`

export const dispatchScannerDeployment = `mutation DispatchScannerDeployment($input: ScannerDeploymentInput!) {
  dispatchScannerDeployment(input: $input) {
    hostId
    listingId
    reservationCode
    lastRequestOn
  }
}
`

export const resetScannerDeployment = `mutation ResetScannerDeployment($input: ScannerDeploymentInput!) {
  resetScannerDeployment(input: $input) {
    reservationCode
    listingId
    hostId
    lastRequestOn
  }
}
`
export const refreshDevices = `mutation RefreshDevices($input: RefreshDevicesInput!) {
  refreshDevices(input: $input) {
    propertyCode
    roomCode
    equipmentId
    equipmentName
    withKeypad
  }
}
`

export const getPropertyLocks = `query GetPropertyLocks($hostPropertyCode: String!) {
  getPropertyLocks(hostPropertyCode: $hostPropertyCode) {
    propertyCode
    roomCode
    equipmentId
    equipmentName
    withKeypad
  }
}
`
/*
export const getOfflinePassword = `query GetOfflinePassword($input: GetPasswordInput!) {
  getOfflinePassword(input: $input) {
    deviceId
    effectiveTime
    invalidTime
    password
  }
}
`

export const getTempPassword = `query GetTempPassword($input: GetPasswordInput!) {
  getTempPassword(input: $input) {
    deviceId
    effectiveTime
    invalidTime
    password
  }
}
`
*/

export const refreshProperty = `mutation RefreshProperty($hostId: String!, $propertyCode: String!) {
  refreshProperty(hostId: $hostId, propertyCode: $propertyCode)
}
`

export const updateHostPms = `mutation updateHostPms($hostId: String!, $input: [UpdateHostPmsInput]) {
  updateHostPms(hostId: $hostId, input: $input) {
    hostId
    pmsType
    pmsUserId
    channelId
  }
}
`

export const initHostPms = `mutation InitHostPms($input: InitHostPmsInput!) {
  initHostPms(input: $input) {
    returnUrl
    expiresAt
  }
}
`

export const getPmsList = `query GetPmsList {
  getPmsList {
    pmsType
    verified
  }
}
`

export const getHostPms = `query GetHostPms($hostId: String!) {
  getHostPms(hostId: $hostId) {
    hostId
    pmsType
    pmsUserId
    channelId
  }
}
`