import store from '@/store'

// function timeout(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms))
// }

const subscribe = (retrying = 0, topic) => {
  setTimeout(async () => {
    // console.log('local.loading', local.loading)
    
    options[topic].loading = true

    console.log('AppSyncSubscriber.subscribe options[topic]', options[topic])

    if (options[topic].initializerKey) {
      await store.dispatch(options[topic].stateKey + '/' + options[topic].initializerKey).catch(e => console.log(e))  
    }

    const observable = await store.dispatch(options[topic].stateKey + '/' + topic)

    subscriptions[topic] = observable.subscribe({
      next: (eventData) => {
        retrying = 0
        console.log('appsyncSubscriber subscribe eventData', eventData)
        if (options[topic].callbackKey) {
          store.dispatch(options[topic].stateKey + '/' + options[topic].callbackKey, eventData.value.data[topic])  
        }
      },
      error: (data) => {
        console.error('subscribe connection errors', data)
        const { errors } = data
        if (errors && errors.find(error => error.message === 'Network Error')) {
          if (retrying === 0) {
            retrying = 50
          }

          subscribe(retrying * 2, topic)
        }
      },
      complete: (data) => console.log('completed', data)
    })

    options[topic].loading = false


  }, retrying)
  
}

const disconnect = (topic) => {
  console.log(`disconnect ${topic}:`, subscriptions[topic])

  if (subscriptions[topic] && typeof subscriptions[topic].unsubscribe === 'function') {
    // console.log('before disconnect subscriptions', subscriptions)
    
    subscriptions[topic].unsubscribe()
    delete subscriptions[topic]
    delete options[topic]

    // console.log('after disconnect subscriptions', subscriptions)
  }
}

const connect = (stateKey, observableKey, initializerKey, callbackKey) => {

  let topic = observableKey

  options[topic] = {
    initializerKey: initializerKey,
    callbackKey: callbackKey,
    stateKey: stateKey,
    loading: false,
  }

  if (options[topic].loading) {
    console.log('connect subscriptions[topic].loading', subscriptions[topic].loading)
    return
  }

  

  if (!subscriptions[topic] || subscriptions[topic].closed) {
    disconnect(topic)
    subscribe(0, topic)
  }
}


const subscriber = {
  connect,
  disconnect,
}

const subscriptions = {}

const options = {}

export default subscriber