import * as AmplifyModules from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import appsync from '@/api/appsync'

const state = {
  hostId: null,
  identityId: null,
  email: null
}

const mutations = {
  setHostId(state, hostId) {
    state.hostId = hostId
  },
  setIdentityId(state, identityId) {
    state.identityId = identityId
  },
  setEmail(state, email) {
    state.email = email
  }  
}

const actions = {
  async login({ commit, dispatch }) {
    
    const [
      authenticatedUser, 
      currentUserInfo, 
    ] = 
      await Promise.all([
        AmplifyModules.Auth.currentAuthenticatedUser(), 
        AmplifyModules.Auth.currentUserInfo(),
      ])

    // console.log('currentAuthenticatedUser', JSON.stringify(authenticatedUser))
    console.log('currentUserInfo', JSON.stringify(currentUserInfo))

    if (authenticatedUser && authenticatedUser.signInUserSession) {
      commit('setHostId', authenticatedUser.username)
    } else {
      throw new Error('currentAuthenticatedUser empty')
    }

    if (currentUserInfo && currentUserInfo.id) {
      commit('setIdentityId', currentUserInfo.id)
    } else {
      throw new Error('currentUserInfo empty')
    }

    if (currentUserInfo && currentUserInfo.attributes && currentUserInfo.attributes.email) {
      commit('setEmail', currentUserInfo.attributes.email)
    } else {
      throw new Error('currentUserInfo empty')
    }

    await dispatch('saveHost').catch(e => {
      console.error('saveHost', e)
      throw e
    })

  },

/*
  async login({ commit }) {
    
    const authenticatedUser = await AmplifyModules.Auth.currentAuthenticatedUser().catch(e => {
      console.error(e)
      throw e
    })

    console.log('authenticatedUser', authenticatedUser)

    if (authenticatedUser && authenticatedUser.signInUserSession) {
      commit('setHostId', authenticatedUser.username)
    }

  },
*/
  async logout({ commit }) {
    
    
    await AmplifyModules.Auth.signOut()
    AmplifyEventBus.$emit('authState', 'signedOut')

    commit('setHostId', null)
  },
  async saveHost({ state }) {

    console.log('state.hostId', state.hostId)
    let rtn = await appsync.getHost(state.hostId)
    if (rtn.errors) {
      throw rtn.errors[0]
    }

    console.log('rtn', rtn)

    if (!rtn.data.getHost) {
      rtn = await appsync.createHost({hostId: state.hostId, identityId: state.identityId, email: state.email})

      if (rtn.errors) {
        throw rtn.errors[0]
      } else {
        const host = rtn.data.createHost
        console.log('rtn.data.createHost', rtn.data.createHost)
      }
    } else if (rtn.data.getHost.identityId != state.identityId) {
      rtn = await appsync.updateHost({
        hostId: state.hostId, 
        identityId: state.identityId,
        email: state.email,
        lastUpdateOn: rtn.data.getHost.lastUpdateOn,
      })

      if (rtn.errors) {
        throw rtn.errors[0]
      } else {
        const host = rtn.data.updateHost
        console.log('rtn.data.updateHost', rtn.data.updateHost)
      }
    }
    
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}