import Papa from 'papaparse'
import ShortUniqueId from 'short-unique-id'
import appsync from '@/api/appsync'
import router from '@/router'
import storage from '@/api/storage'
import { CsvProcessor } from '@/utils'
import PhoneNumber from "awesome-phonenumber"

// const defaultSecret = '**********'

const uid = new ShortUniqueId()

const state = {
  // listingId: null,
  // listingName: null,
  listing: null,
  guideUrl: null,
  reservation: null,
  lockDeviceId: null,
  keypadDeviceId: null,
  listings: [],
  reservations: [],
  members: [],
  notifications: [],
  reservationRooms: null,
  faceImgKeyCount: 0,
  host: null,
  devices: [],
  locks: [],
  keypads: [],
  // defaultSecret: defaultSecret,
  pmsList: [],
  hostPms: null,
  returnUrl: null,
  expiresAt: null
}

const actions = {
  async loadListingLocks ({ commit, state, rootState }) {
    console.log('loadListingLocks')
    
    const rtn = await appsync.getPropertyLocks(`${state.listing.hostId}-${state.listing.propertyCode}`)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const keypads = rtn.data.getPropertyLocks.filter(device => {
        return device.withKeypad
      }).map(device => {
        delete device.withKeypad
        delete device.propertyCode
        return device
      })

      const locks = rtn.data.getPropertyLocks.filter(device => {
        return !(device.withKeypad)
      })

      console.log('locks', locks)

      console.log('keypads', keypads)

      commit('setLocks', locks)

      commit('setKeypads', keypads)
    }
  },
  async loadListings ({ commit, rootState }) {
    // console.log('rootState.session', rootState.session)

    const rtn = await appsync.getListings(rootState.session.hostId)
    console.log('listings', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const listings = rtn.data.getListings

      commit('setListings', listings)
    }
  },
  async createListing ({ commit, state, rootState }, val) {
    val.hostId = rootState.session.hostId
    val.listingId = uid.randomUUID(6)
    val.propertyCode = val.internalName.split(' ')[0]

    if (!val.pmsUserId || !val.pmsListingId) {
      delete val.pmsUserId
      delete val.pmsListingId
    }

    const rtn = await appsync.createListing(val)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const listing = rtn.data.createListing

      await appsync.refreshProperty(listing.hostId, listing.propertyCode)

      state.listings.push(listing)
    }
  },
  async updateListing ({ commit, state }, val) {
    const rtn = await appsync.updateListing(val.params)
    console.log('updateListing before', val.params)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      console.log('updateListing after', rtn.data.updateListing)
      const listing = rtn.data.updateListing

      console.log('updateListing listings before', state.listings)
      Object.assign(state.listings[val.index], listing)
      console.log('updateListing listings after', state.listings)
    }
  },
  async importListings ({ commit, dispatch, state }) {
    const rtn = await appsync.importListings(state.hostPms)

    console.log('importListings rtn', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      // state.listings = rtn.data.importListings
      await dispatch('loadListings')
    }
  },
  async loadMembers ({ commit, state }) {

    const rtn = await appsync.getMembers(state.reservation.reservationCode)
    console.log('loadMembers rtn', rtn)
    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const members = rtn.data.getMembers

      if (members.length < state.reservation.memberCount) {
        const existingMemberNos = members.map((item) => {
          return item.memberNo
        })

        let i = 1

        for (i; i <= state.reservation.memberCount; i++) {
          if (existingMemberNos.indexOf(i) === -1) {
            members.push({ 
              reservationCode: state.reservation.reservationCode, 
              memberNo: i,
              keyInfo: state.reservation.keyInfo,
            })
          }
        }
      } else {
        members.forEach(member => {
          if (!member.keyInfo) {
            member.keyInfo = state.reservation.keyInfo
          }
        })
      }

      members.sort((a, b) => {
        if (a.memberNo < b.memberNo)
          return -1
        if (a.memberNo > b.memberNo)
          return 1
        return 0
      })

      commit('setMembers', members)
    }  

  },
  async createMember ({ commit, state, rootState }, val) {
    console.log('createMember', val)

    const rtn = await appsync.createMember(val)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const member = rtn.data.createMember

      const index = member.memberNo - 1
      Object.assign(state.members[index], member)
    }
  },
  async updateMember ({ commit, state }, val) {
    console.log('updateMember', val)
    
    const rtn = await appsync.updateMember(val)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const member = rtn.data.updateMember

      const index = member.memberNo - 1
      Object.assign(state.members[index], member)
      
    }
  },
  async validateCalendar ({ commit, state }, val) {
    console.log('validateCalendar', val)

    val.params.listingId = state.listing.listingId

    const rtn = await appsync.getCalendars(val.params)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      return rtn.data.getCalendars
    }
  },
  async onUpdateReservation ({ state, rootState }) {
    console.log('host.js onUpdateReservation rootState.session.hostId', rootState.session.hostId)
    console.log('host.js onUpdateReservation state.listing.listingId', state.listing.listingId)
    return appsync.onUpdateReservation(rootState.session.hostId, state.listing.listingId)
  },
  async onRemoveReservation ({ state, rootState }) {
    return appsync.onRemoveReservation(rootState.session.hostId)
  },
  async updateReservation ({ commit, state, rootState }, val) {
    console.log('host.updateReservation', val)

    val.phoneNo = PhoneNumber(val.phoneNo).getNumber('e164')

    delete val.notificationResponses

    const rtn = await appsync.generateReservation(val)

    if (rtn.errors) {
      throw rtn.errors[0]
    }
  },
  async generateReservation ({ commit, state, rootState }, val) {
    console.log('host.generateReservation', val)

    val.hostId = rootState.session.hostId
    val.listingId = state.listing.listingId
    // val.listingName = state.listingName
    // delete val.reservationCode

    // Normalize Phone Number, Added on 20190513
    const normalizedNo = PhoneNumber(val.phoneNo).getNumber('e164')
    val.phoneNo = normalizedNo
    // Normalize Phone Number, Added on 20190513

    delete val.notificationResponses

    const rtn = await appsync.generateReservation(val)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      // const reservation = rtn.data.generateReservation

      // state.reservations.push(reservation)
    }
  },
  async updateReservationStatus ({ commit, state }, params) {
    console.log('store.host.updateReservationStatus in: params:', params)

    const rtn = await appsync.updateReservationStatus(params)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
    }
  },
  async updateIdVerified ({ commit, state }, val) {
    console.log('store.host.updateIdVerified in: val:', val)

    delete val.notificationResponses

    const rtn = await appsync.updateIdVerified(val)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
    }
  },
  async notifyGuest ({ commit, state }, val) {
    console.log('store.host.notifyGuest in: val:', val)

    delete val.reservation.notificationResponses

    const rtn = await appsync.notifyGuest(val.reservation, val.messageType)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
    }
  },
  async removeReservation ({ commit, state }, val) {
    console.log('store.host.removeReservation in: val:', val)

    delete val.notificationResponses

    const rtn = await appsync.removeReservation(val)

    if (rtn.errors) {
      throw rtn.errors[0]
    }
  },
  async loadReservations ({ commit, state }) {
    const rtn = await appsync.getReservations(state.listing.listingId)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {

      const reservations = rtn.data.getReservations
      console.log('reservations', reservations)

      commit('setReservations', reservations)
    }
  },
  postRemoveReservations ({ state, commit }, val) {

    console.log('postRemoveReservations', val)

    const newReservations = state.reservations.filter(reservation => reservation.reservationCode != val.reservationCode)

    commit('setReservations', newReservations)

  },
  refreshReservations ({ state }, val) {

    console.log('refreshReservations', val)
  
    const index = state.reservations.findIndex((crt, crtIndex) => crt.reservationCode == val.reservationCode)

    if (index > -1) {
      Object.assign(state.reservations[index], val)
    } else {
      state.reservations.push(val)
    }

  },
  gotoListings ({ commit }, val) {
    console.log('gotoListings', val)

    router.push({path: '/'})
  },  
  gotoReservations ({ commit }, val) {
    console.log('gotoReservations', val)

    // commit('setListingId', val.listingId)
    // commit('setListingName', val.listingName)
    // commit('setGuideUrl', val.guideUrl)

    commit('setListing', val)

    router.push({path: '/reservations'})
  },
  gotoMembers ({ commit }, val) {
    console.log('gotoReservation', val)

    commit('setReservation', val)

    if (router.currentRoute.name === 'members') {
      router.go()
    } else {
      router.push({path: '/members'})
    }
    
  },  
  pickListing ({ commit }, val) {
    console.log('pickListing', val)

    commit('setListing', val)

  },
  pickReservation ({ commit }, val) {
    console.log('pickReservation', val)

    commit('setReservation', val)

  },
  async uploadJson ({ rootState, state, dispatch }, { fileObject }) {
    // console.log('uploadJson', fileObject)

    const result = await dispatch('parseFile', fileObject).catch(e => {
      console.error('parseFile', e)
      throw e
    })

    // console.log('parseFile result', result)

    const reservations = result.map(item => {
      
      item.listingId = state.listing.listingId
      item.hostId = rootState.session.hostId

      return item
    })

    console.log('parseFile reservations', reservations)

    // await storage.putJson(uid.randomUUID(8) + '.json', reservations)

    await storage.putJson(`reservations/json/${rootState.session.hostId}/${state.listing.listingId}/${uid.randomUUID(8)}.json`, reservations)
  },
  async uploadCsv ({ rootState, state, dispatch }, csvFile ) {
    console.log('uploadCsv', csvFile.file)

    await storage.putCsv(`reservations/csv/${rootState.session.hostId}/${state.listing.listingId}/${uid.randomUUID(8)}.csv`, csvFile.file)
  },  
  readFile ({ commit }, fileObject) {
    return new Promise((resolve, reject) => {

        const reader = new FileReader()
        reader.onload = (event) => {
            resolve(event.target.result)
        }

        reader.readAsArrayBuffer(fileObject)
    })
  },
  parseFile ({ commit }, fileObject) {
    return new Promise((resolve, reject) => {

      Papa.parse(fileObject, {
        header: true,
        skipEmptyLines: true,
        beforeFirstChunk: chunk => {
            const rows = chunk.split( /\r\n|\r|\n/ )
            console.log('parseFile', rows)
            const headings = rows[0].split( ',' ).map(header => header.replaceAll('"',''))

            const newHeadings = CsvProcessor.transformHeaderAirbnb(headings)
            rows[0] = newHeadings.join()

            return rows.join('\n')
        },
        transform: (value, columnNo) => {
          try {
            return CsvProcessor.transformAirbnb(value, columnNo)
          } 
          catch (error) {
            reject('transformAirbnb', error)
          }

          // return CsvProcessor.transformAirbnb(value, columnNo)
          
        },
        complete: results => {
          //todo getrid of erro by results.error
          console.log('parseFile complete results', results)
          resolve(results.data)
        }
      })

    })
  },
  // async onCreateNotification ({ rootState }) {
  //   return appsync.onCreateNotification(rootState.session.hostId)
  // },
  // async loadNotifications ({ commit, rootState }) {
  //   // console.log('loadNotifications rootState.session.hostId', rootState.session.hostId)

  //   const rtn = await appsync.getNotifications(rootState.session.hostId)
  //   console.log('getNotifications', rtn)

  //   if (rtn.errors) {
  //     throw rtn.errors[0]
  //   } else {

  //     const notifications = rtn.data.getNotifications

  //     commit('setNotifications', notifications)
  //   }
  // },
  // addNotification ({ state }, onCreateNotificationData) {
  //   state.notifications.push(onCreateNotificationData)

  //   console.log('state.notifications.length', state.notifications.length)
  // },
  // async removeNotification ({ state }, notification) {

  //   const params = {
  //     hostId: notification.hostId,
  //     reservationCode: notification.reservationCode
  //   }

  //   const rtn = await appsync.deleteNotification(params)

  //   if (rtn.errors) {
  //     throw rtn.errors[0]
  //   } else {
  //     const data = rtn.data.deleteNotification
  
  //     const index = state.notifications.indexOf(notification)
  //     if (index > -1) {
  //       state.notifications.splice(index, 1)
  //     }
  //   }
  // },
  async onUpdateDevice ({ state }) {
    return appsync.onUpdateDevice(state.host.listing.listingId)
  },
  async putImage ({ commit, state, rootState }, { member, idImgFile, faceImgFile }) {
    // console.log('host putImage idImgKey', idImgKey)
    // console.log('host putImage file.file', file.file)
    // console.log('host putImage file.type', file.type)
    if (idImgFile.file) {
      console.log('host putImage idImgFile in')

      const imgKey = `${rootState.session.hostId}/${state.listing.listingId}/${member.reservationCode}/id/${member.memberNo}.jpg`

      await storage.putImage(imgKey, idImgFile.file, idImgFile.type)

      console.log('host putImage idImgFile out')
    }

    if (faceImgFile.file) {
      console.log('host putImage faceImgFile in')

      const imgKey = `${rootState.session.hostId}/${state.listing.listingId}/${member.reservationCode}/face/${member.memberNo}.jpg`

      await storage.putImage(imgKey, faceImgFile.file, idImgFile.type)

      console.log('host putImage faceImgFile out')
    }
  }, 
  async validateFaceImgs ({ commit, state, rootState }) {

    const rtn = await appsync.getMembers(state.reservation.reservationCode)
    
    console.log('getMembers rtn', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const members = rtn.data.getMembers

      const roomSet = new Set()
      let faceImgKeyCount = 0

      members.forEach(member => {
        if (member.memberKeyItem) {
          roomSet.add(member.memberKeyItem.roomCode)
        }

        if (member.faceImgKey) {
          faceImgKeyCount++
        }
        
      })

      if (faceImgKeyCount < state.reservation.memberCount || faceImgKeyCount == 0) {
        throw new Error('Face image of each member is required!') 
      }

      if (roomSet.size == 0) {
        throw new Error('Rooms not specified!')
      }

      roomSet.forEach(roomCode => {
        if (!rootState.scanner.scannerSetup.has(roomCode)) {
          throw new Error("The member's room information does not match the scanner's room information:" + roomCode)
        }
      })

    }  

  },
  async updateHost ({ commit, state }, val) {
    console.log('updateHost', val)

    // if (val.smartlockProperty.clientId == defaultSecret || val.smartlockProperty.clientSecret == defaultSecret) {
    //   delete val.smartlockProperty.clientId
    //   delete val.smartlockProperty.clientSecret
    // }

    const rtn = await appsync.updateHost(val)

    if (rtn.errors) {
      throw rtn.errors[0]
    }

    commit('setHost', rtn.data.updateHost)
  },
  async loadHost ({ commit, rootState }) {

    const rtn = await appsync.getHost(rootState.session.hostId)
    console.log('loadHost', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const host = rtn.data.getHost

      if (!host.smartlockProperty) {
        host.smartlockProperty = {}
      }
      commit('setHost', host)
    }
  },
  async refreshDevices ({ commit, rootState }, val) {
    console.log('refreshDevices', val)

    const rtn = await appsync.refreshDevices(val)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      console.log('refreshDevices', rtn.data.refreshDevices)
    }
  },
  async verifyChannel ({ commit, state }, {inviteCode, hostId}) {
    console.log('verifyChannel', {inviteCode, hostId})

    const rtn = await appsync.verifyChannel(inviteCode, hostId)

    console.log('verifyChannel rtn', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    }

    commit('setHost', rtn.data.verifyChannel)
  },
/*
  async getOfflinePasswords ({ commit, dispatch }, requests) {
    console.log('getOfflinePasswords', requests)

    const smartLocks = requests.filter(request => {
      if (request.deviceId) {
        return true;
      } else {
        return false;
      }
    });

    const staticLocks = requests.filter(request => {
      if (request.deviceId) {
        return false;
      } else {
        return true;
      }
    });

    const responses = await Promise.all(smartLocks.map(async (request) => {

      const password = await dispatch('getOfflinePassword', {
        deviceId: request.deviceId,
        effectiveTime: request.effectiveTime,
        invalidTime: request.invalidTime
      }).catch(e => {
        console.error('getOfflinePasswords', e)
        throw e
      })

      return {
        roomCode: request.roomCode,
        keyInfo: password,
        deviceId: request.deviceId
      }

    }));

    return responses.concat(staticLocks)
  },  
  async getOfflinePassword ({ commit, rootState }, val) {
    console.log('getOfflinePassword', val)

    const rtn = await appsync.getOfflinePassword({
      hostId: rootState.session.hostId,
      deviceId: val.deviceId,
      effectiveTime: val.effectiveTime,
      invalidTime: val.invalidTime
    })

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      console.log('getOfflinePassword', rtn.data.getOfflinePassword)
    }

    return rtn.data.getOfflinePassword.password
  },
  async getTempPassword ({ commit, rootState }, val) {
    console.log('getTempPassword', val)

    const rtn = await appsync.getTempPassword({
      hostId: rootState.session.hostId,
      deviceId: val.deviceId,
      effectiveTime: val.effectiveTime,
      invalidTime: val.invalidTime,
      password: val.password,
      relatedDeviceIds: val.relatedDeviceIds
    })

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      console.log('getTempPassword', rtn.data.getTempPassword)
    }

    return rtn.data.getTempPassword.password
  },
*/
  async updateHostPms ({ commit, rootState }, val) {
    console.log('updateHostPms', val)

    let rtn
    if (val[0].pmsType) {
      rtn = await appsync.updateHostPms(rootState.session.hostId, val)
    } else {
      rtn = await appsync.updateHostPms(rootState.session.hostId)
    }

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      if (rtn.data.updateHostPms?.length > 0) {
        commit('setHostPms', rtn.data.updateHostPms[0])
      }
    }



  },  
  async initHostPms ({ commit, rootState }, pmsType) {
    console.log('initHostPms', pmsType)

    const rtn = await appsync.initHostPms({
      hostId: rootState.session.hostId,
      pmsType: pmsType
    })

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      console.log('initHostPms', rtn.data.initHostPms)

      commit('setReturnUrl', rtn.data.initHostPms.returnUrl)

      commit('setExpiresAt', rtn.data.initHostPms.expiresAt)
    }
  },
  async getPmsList ({ commit, rootState }) {
    console.log('getPmsList')

    const rtn = await appsync.getPmsList()
    
    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      console.log('getPmsList', rtn)
      commit('setPmsList', rtn.data.getPmsList)
    }
  },
  async getHostPms ({ commit, rootState }) {
    console.log('getHostPms')
    console.log('getHostPms hostId', rootState.session.hostId)

    const rtn = await appsync.getHostPms(rootState.session.hostId)
    
    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      console.log('getHostPms', rtn)
      if (rtn.data.getHostPms.length > 0) {
        commit('setHostPms', rtn.data.getHostPms[0])  
      } else {
        commit('setHostPms', null)
      }
    }
  }
}

const mutations = {
  setExpiresAt(state, val) {
    state.expiresAt = val
  },
  setReturnUrl(state, val) {
    state.returnUrl = val
  },
  setHostPms(state, val) {
    state.hostPms = val
  },
  setPmsList(state, val) {
    state.pmsList = val
  },
  setHost(state, val) {
    state.host = val
  },
  setMembers(state, val) {
    state.members = val
  },
  setNotifications(state, val) {
    state.notifications = val
  },
  setReservations(state, val) {
    state.reservations = val
  },
  setListing(state, val) {
    state.listing = val
  },
  setListings(state, val) {
    state.listings = val
  },
  // setListingId(state, val) {
  //   state.listingId = val
  // },
  // setListingName(state, val) {
  //   state.listingName = val
  // },
  setGuideUrl(state, val) {
    state.guideUrl = val
  },
  setReservation(state, val) {
    state.reservation = val
  },
  setReservationRooms(state, val) {
    state.reservationRooms = val
  },
  setFaceImgKeyCount(state, val) {
    state.faceImgKeyCount = val
  },
  setDevices(state, val) {
    state.devices = val
  },
  setLocks(state, val) {
    state.locks = val
  },
  setKeypads(state, val) {
    state.keypads = val
  },
}

const getters = {
  allIdVerified: state => {
    console.log('state.members.length', state.members.length)

    if (state.members.length > 0) {
      return state.members.every(crtMember => crtMember.idVerified)  
    } else {
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}