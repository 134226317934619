import { EventBus } from '@/eventBus'

const CallDialog = function (error) {

	if (error) {

		console.error('calldialog', JSON.stringify(error))

	  if (error.errorType == Exceptions.ChangedByOthers.errorType) {
	    showWarningDialog({
	      message: Exceptions.ChangedByOthers.errorType,
	      stack: Exceptions.ChangedByOthers.message
	    })
	  } else if (error.errorType == Exceptions.CustomNoReservation.errorType) {
	    showWarningDialog({
	      message: Exceptions.CustomNoReservation.message,
	      stack: error.message
	    })
	  } else {
	    showWarningDialog(error)
	  }

	}
}

const showWarningDialog = function (error) {

  EventBus.$emit('WarningDialog', { 
    showDialog: true, 
    errorType: error.errorType,
    errorMessage: error.message,
    errorStack: error.stack,
  })
}


const Exceptions = {
	ChangedByOthers: {
		errorType: "DynamoDB:ConditionalCheckFailedException",
		message: "The data has been changed by another session. Please refresh or sign out."
	},
	CustomNoReservation: {
    	errorType: "DynamoDB:CustomNoData",
    	message: "There is no reservation available for this user."
  	}
}

export default CallDialog

export {Exceptions}