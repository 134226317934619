import Vue from 'vue'

const WindowStatePlugin = {
  install: function(Vue) {
    
    const state = {
      scrollX: 0,
      scrollY: 0,
      width: 0,
      height: 0
    }
    
    const onScroll = function() {
      state.scrollX = window.pageXOffset
      state.scrollY = window.pageYOffset
    }
    document.addEventListener('scroll', onScroll)
    
    const onResize = function() {
      state.width = document.documentElement.clientWidth
      state.height = document.documentElement.clientHeight
    }
    window.addEventListener('resize', onResize)
    onResize()
    
    
    window.addEventListener('load', onScroll)
    
    Vue.util.defineReactive(Vue.prototype, '$window', state)
  }
}

export default WindowStatePlugin