<template>
  <v-app>
    <top-menu/>
    <v-main>
      <router-view/>
    </v-main>
    <warningDialog @closeWarningRefresh="onCloseWarningRefresh" @closeWarningSignOut="onCloseWarningSignOut"></warningDialog>
  </v-app>
</template>

<script>
import Vue from 'vue'
// import Menu from '@/components/Menu.vue'
const Menu = () => import('@/components/Menu.vue')
// import WarningDialog from '@/components/WarningDialog.vue'
const WarningDialog = () => import('@/components/WarningDialog.vue')

export default {
  name: 'app',
  components: {
    // modalDialog: ModalDialog,
    warningDialog: WarningDialog,
    topMenu: Menu
  },
  methods: {
    async onCloseWarningSignOut() {
      this.$store.dispatch('session/logout')
        .catch(e => console.log('logout!!!', e))
    },
    async onCloseWarningRefresh() {
      this.$router.go()
    }
  }
}
</script>


<style>
body {
  margin: 0;
  background-color: white;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align:center;
}
</style>