import PhoneNumber from "awesome-phonenumber"

export function transformHeaderAirbnb(headers) {

  headers.forEach((header, index, thisArray) => {

    if (header === 'Confirmation code') {
      thisArray[index] = 'bookingId'
    } else if (header === 'Guest name') {
      thisArray[index] = 'guestName'
    } else if (header === 'Contact') {
      thisArray[index] = 'phoneNo'
    } else if (header === 'Listing') {
      thisArray[index] = 'listingName'
    } else if (header === 'Start date') {
      thisArray[index] = 'checkInDate'
    } else if (header === 'End date') {
      thisArray[index] = 'checkOutDate'
    } else if (header === '# of adults') {
      thisArray[index] = 'numberOfAdults'
    } else if (header === '# of children') {
      thisArray[index] = 'numberOfChildren'
    } else if (header === '# of infants') {
      thisArray[index] = 'numberOfInfants'
    } else if (header === '# of nights') {
      thisArray[index] = 'numberOfNights'
    }
  })

  return headers
}

export function transformAirbnb(value, columnNo) {
  // console.log('transformAirbnb in', {value, columnNo})

  // checkInDate, checkOutDate, booked
  if (columnNo === 'checkInDate' || columnNo === 'checkOutDate') {
    return value.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2")
  }

  if (columnNo === 'Booked') {
    return value.replace(/(\d{4})\/(\d\d)\/(\d\d)/, "$1-$2-$3")
  }

  if (columnNo === 'phoneNo') {
    if (!value) {
      throw Error ('No Phone Number!!')
    } else {
      const quoteRemoved = value.replace(/'/g, "")
      const phoneNo = PhoneNumber(quoteRemoved).getNumber('e164')
      if (!phoneNo) {
        throw Error (`Invalid Phone Number: ${value}`)
      }

      return phoneNo
    }
  }

  // console.log('transformAirbnb out', value)
  return value
}

export default { transformHeaderAirbnb,  transformAirbnb }