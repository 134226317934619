import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Amplify, { Storage, Analytics } from 'aws-amplify'
import * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import devConfig from '@/aws-exports-dev'
import prodConfig from '@/aws-exports-prod'
import aws_exports from '@/aws-exports'
// import { components } from 'aws-amplify-vue'
import VueI18n from 'vue-i18n'
import messages from '@/assets/i18n'
import * as utils from '@/utils'

console.log("main.js aws_exports", JSON.stringify(aws_exports))
console.log("main.js devConfig", JSON.stringify(devConfig))
console.log("main.js prodConfig", JSON.stringify(prodConfig))
console.log("main.js VUE_APP_USER_BRANCH", process.env.VUE_APP_USER_BRANCH)

let amplifyConfig
if (process.env.VUE_APP_USER_BRANCH == 'prod') {
  amplifyConfig = prodConfig
} else if (process.env.VUE_APP_USER_BRANCH == 'dev') {
  amplifyConfig = devConfig
} else {
  amplifyConfig = devConfig
}

Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(VueI18n)
Vue.use(utils.WindowStatePlugin)
// Vue.use(VueCookies)
// VueCookies.config('1y')

Vue.config.productionTip = false

// Amplify config
Amplify.configure(amplifyConfig)
Storage.configure({ level: 'private' })
Analytics.configure({ disabled: true })


// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'gb', // set locale
  messages // set locale messages
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  components: { 
    App,
    // ...components
  }
}).$mount('#app')