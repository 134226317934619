import { Storage } from 'aws-amplify'

export default {
  async putCsv (fileName, data, fileType = "text/csv", s3level = "private") {
    const rtn = await Storage.put(fileName, data,
      {
        level: s3level,
        contentType: fileType
      })
      .catch(e => console.log('storage.put',e))

    return rtn
  },
  async putJson (fileName, data) {
    const rtn = await Storage.put(fileName, 
      JSON.stringify(data), {
        level: 'private',
        contentType: 'application/json'
      })
      .catch(e => console.log('storage.put',e))

    return rtn
  },
  async putImage(fileName, data, fileType = "binary/octet-stream", s3level = "private") {
    await Storage.put(fileName, data, {
      level: s3level,
      contentType: fileType,
    }).catch(e => {
      console.log("putImage error", e)
      throw e
    })
  },
  async get (imgName) {
    const url = await Storage.get(imgName, {level: 'private'})
      .catch(e => console.log('storage.get',e))

    return url
  }
}
