
import appsync from '@/api/appsync'
import router from '@/router'

const state = {
  listing: null,
  scanners: [],
  scanRecords: [],
  scannerSetup: null,
  nowTime: new Date(),
  terminalKey: null
}

const actions = {
  gotoScanRecords ({ commit }, val) {
    console.log('gotoScanRecords', val)

    commit('setTerminalKey', val.terminalKey)

    router.push({path: '/scanRecords'})
  },
  gotoScanners ({ commit }, val) {
    console.log('gotoScanners', val)

    commit('setListing', val)

    router.push({path: '/scanners'})
  },
  async loadScanRecords ({ commit, state, rootState }) {

    const rtn = await appsync.getScanRecords(state.terminalKey)
    console.log('getScanRecords', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const getScanRecords = rtn.data.getScanRecords

      commit('setScanRecords', getScanRecords)
    }
  },
  async loadScanners ({ commit, state, rootState }) {

    const rtn = await appsync.getScanners(`${state.listing.hostId}-${state.listing.propertyCode}`)
    console.log('getScanners', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const scanners = rtn.data.getScanners

      commit('setScanners', scanners)
    }
  },
  async validateScanners ({ commit, state, rootState }) {

    const rtn = await appsync.getScanners(`${rootState.host.listing.hostId}-${rootState.host.listing.propertyCode}`)
    console.log('validateScanners', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const scanners = rtn.data.getScanners
      const scannerSetup = new Set()

      scanners.forEach(scanner => {
        scannerSetup.add(scanner.roomCode)
      })

      if (scannerSetup.size == 0) {
        throw new Error('Scanner not found!')
      }

      commit('setScannerSetup', scannerSetup)

    }
  },
  async dispatchScannerDeployment ({ commit, state, rootState }, val) {

    val.hostId = rootState.session.hostId;
    val.propertyCode = rootState.host.listing.propertyCode;

    const rtn = await appsync.dispatchScannerDeployment(val)
    console.log('dispatchScannerDeployment', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      return rtn.data.dispatchScannerDeployment
    }
  },
  async resetScannerDeployment ({ commit, state, rootState }, val) {

    val.hostId = rootState.session.hostId;
    val.propertyCode = rootState.host.listing.propertyCode;

    const rtn = await appsync.resetScannerDeployment(val)
    console.log('resetScannerDeployment', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      return rtn.data.resetScannerDeployment
    }
  },
  async onCreateScanner ({ rootState }) {
    return appsync.onCreateScanner(rootState.session.hostId)
  },
  refreshScanners ({ state }, scanner) {
  
    const index = state.scanners.findIndex((crt, crtIndex) => crt.terminalKey == scanner.terminalKey)

    if (index > -1) {
      Object.assign(state.scanners[index], scanner)
    } else {
      state.scanners.push(scanner)
    }

  },
  async onCreateScanRecord ({ state, rootState }) {
    return appsync.onCreateScanRecord({
      hostId: rootState.session.hostId,
      terminalKey: state.terminalKey
    })
  },
  refreshScanRecords ({ state }, scanRecord) {
  
    state.scanRecords.push(scanRecord)
  },
  startTimer ({ commit }) {        
    setInterval(() => { 
      commit('setNowTime', new Date())
    }, 1000)        
  },
}

const mutations = {
  setScanners(state, val) {
    state.scanners = val
  },
  setScanRecords(state, val) {
    state.scanRecords = val
  },
  setTerminalKey(state, val) {
    state.terminalKey = val
  },
  setScannerSetup(state, val) {
    state.scannerSetup = val
  },
  setNowTime(state, val) {
    state.nowTime = val
  },
  setListing(state, val) {
    state.listing = val
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}