const messages = {
  gb: {
    listing: {
      title: 'Listings',
      btn_new: 'Add Listing',
      btn_edit: 'Edit Listing',
      btn_save: 'Save',
      btn_cancel: 'Cancel',      
      table_search: 'Search',
      table_edit: 'Edit',
      table_listing_name: 'Listing Name',
      table_listing_id: 'Listing ID',
      table_manual_check: 'Manual Guest Check',
      table_activated: 'Activated',
      table_guide: 'Listing Guide',
      table_payment: 'Payment Status',
      table_registerd_on: 'Registered On',
      table_goto_reservations: 'Reservations',
      table_goto_scanners: 'Scanners'
    },
    reservation: {
      title: 'Reservations',
      btn_new: 'Add',
      btn_import: 'Import',      
      btn_edit: 'Edit Reservation',
      btn_save: 'Save',
      btn_cancel: 'Cancel',
      msg_guest_name: 'Guest name required!',
      msg_phone_no: 'Phone number required!',
      msg_member_count: 'Member Count required!',
      msg_checkin_date: 'Check-in date required!',
      msg_checkout_date: 'Check-out date required!',
      table_search: 'Search',
      table_edit: 'Edit',
      table_reservation_code: 'Reservation Code',
      table_guest: 'Guest Name',
      table_booking_id: 'Booking ID',
      table_phone_no: 'Phone Number',
      table_email: 'Email',
      table_member_count: 'Member Count',
      table_checkin_date: 'Check-in Date',
      table_checkout_date: 'Check-out Date',
      table_key_info: 'Key Info',
      table_deploy: 'Deploy',
      table_last_request: 'Last Deployment On',
      table_remove: 'Remove Deployment',
      table_goto_members: 'Check Members',
      table_smart_lock: 'Smart Lock',
      table_smart_lock_keypad: 'Smart Lock Keypad'
    },    
    member: {
      btn_export: 'Export Data to CSV',
      btn_deploy: 'Deploy',
      btn_remove: 'Remove Deployment',
      btn_save: 'Save',
      btn_cancel: 'Cancel',      
      title: 'Members',
      title_sub1: 'ID Pictures',
      title_sub2: 'Face Pictures',
      title_sub3: 'Member Details',
      title_sub4: 'Edit Member Detail',
      drop_id: 'Drop ID Picture Here',
      drop_face: 'Drop Face Picture Here',
      label_fullname: 'Guest Name',
      label_nationality: 'Nationality',
      label_id_no: 'ID Number',
      label_phone_no: 'Phone Number',
      label_birth_date: 'Birth date',
      label_gender: 'Gender',
      label_occupation: 'Occupation',
      label_address: 'Address',
      label_room_number: 'Room Number',
      label_key_info: 'Room Key',
      label_prev_place: 'Previous Place',      
      label_next_dest: 'Next Destination',
      label_guest: 'Guest',
      label_valid_id: 'ID Is Valid',
      label_id_matches: 'ID Matches Face',
      label_check_detail: 'Check Detail',
    },
    scanner: {
      title: 'Scanners',
      table_search: 'Search',
      table_listing_id: 'Listing ID',
      table_listing_name: 'Listing Name',
      table_core: 'Gateway Name',
      table_terminal_name: 'Scanner Name',
      table_terminal_key: 'Scanner Key',
      table_room_code: 'Room Number',
      table_ip: 'IP Address',
      table_latitude: 'Latitude',
      table_longitude: 'Longitude',
      table_last_update: 'Last Heartbeat On',
      table_last_heartbeat: 'Since Last Heartbeat'
    },
    menu: {
      gb: 'EN',
      hk: 'CN',
      jp: 'JP'
    },
    about: {
      timeline_add_reservation: 'After the reservation is confirmed the host adds the information with manual, automatic or batch-import approaches.',
      timeline_upload_id: 'The guest retreives the login information of GoCheckIn. At the GoCheckIn Site the identity pictures of all the members are taken and uploaded.',
      timeline_verify_id: 'The host verifies the identity pictures and other mandatory information.',
      timeline_guidebook: 'After the verification by the host the guest will be able to get the guidebook.',
      timeline_match_id: 'At the accommodation each members takes a face picture to match the previously taken identity picture.',
      timeline_host_verify: 'Self Check-in will be done without the intervention of the host unless the special situations listed below: 1) The host is unable to verify all the identities before the guest arrives. 2) Any members re-takes the identity picture during identity-maching at the accommodation. 3) Special cases such as check-in of separated groups or the number of guests has to be changed.',
      timeline_key: 'Identity-matching successfully completed，The guest manages to check in and get the key info.',
      timeline_guest_title_remote: 'Guest Action (Before the trip)',
      timeline_guest_title_site: 'Guest Action(At the accommodation)',
      timeline_host_title: 'Host Operation',
    }
  },
  hk: {
    listing: {
      title: '物業明細',
      btn_new: '新登記物業',
      btn_edit: '修改物業設置',
      btn_save: '保存',
      btn_cancel: '取消',      
      table_search: '搜索',
      table_edit: '編集',
      table_listing_name: '物業名',
      table_listing_id: '物業ID',
      table_manual_check: '身份確認',
      table_guide: '物業使用說明',
      table_payment: '付款狀態',
      table_registerd_on: '登記日期',
      table_goto_reservations: '預約明細',
      table_goto_scanners: '人臉掃描儀明細'
    },
    reservation: {
      title: '預約明細',
      btn_new: '新增',
      btn_import: '批量輸入',      
      btn_edit: '編輯預約內容',
      btn_save: '保存',
      btn_cancel: '取消',
      msg_guest_name: '需要填寫訪客代表姓名!',
      msg_phone_no: '需要填寫電話聯繫方式',
      msg_member_count: '需要填寫訪客人數!',
      msg_checkin_date: '需要明確入住日期!',
      msg_checkout_date: '需要明確退房日期!',
      table_search: '搜索',
      table_edit: '編輯',
      table_reservation_code: '預約號碼',
      table_booking_id: '外部預約ID',
      table_guest: '訪客代表姓名',
      table_phone_no: '電話號碼',
      table_email: '電郵',
      table_member_count: '訪客人數',
      table_checkin_date: '入住日期',
      table_checkout_date: '退房日期',
      table_key_info: '鑰匙信息',
      table_deploy: '配置人臉掃描儀',
      table_last_request: '上次配置日期時間',
      table_remove: '清除人臉掃描儀配置',
      table_goto_members: '確認住客',
      table_smart_lock: 'Smart Lock',
      table_smart_lock_keypad: 'Smart Lock Keypad'
    },      
    member: {
      btn_export: '保存房客信息為CSV',
      btn_deploy: '配置人臉掃描儀',
      btn_remove: '清除人臉掃描儀配置',      
      title: '訪客明細',
      title_sub1: '訪客ID',
      title_sub2: '訪客照片',
      title_sub3: '訪客詳細信息',
      drop_id: 'ID照片拖入這裡',
      drop_face: '人臉照片拖入這裡',
      label_fullname: '姓名',
      label_nationality: '國籍',
      label_id_no: 'ID號碼',
      label_phone_no: '電話號碼',
      label_birth_date: '出生日期',
      label_gender: '性別',
      label_occupation: '職業',
      label_address: '住址',
      label_room_number: '房間號',
      label_key_info: '鑰匙信息',
      label_prev_place: '入住前居住地',      
      label_next_dest: '退房後目的地',
      label_guest: '訪客',
      label_valid_id: 'ID號碼正確',
      label_id_matches: 'ID照片符合人臉',
      label_check_detail: '確認詳細信息',      
    },
    scanner: {
      title: '人臉掃描儀',
      table_search: '搜索',
      table_listing_id: '物業ID',
      table_listing_name: '物業名',
      table_core: '智能網關',
      table_terminal_name: '人臉掃描儀',
      table_terminal_key: '人臉掃描儀序列號',
      table_room_code: '設置房間號',
      table_ip: 'IP地址',
      table_latitude: '緯度',
      table_longitude: '經度',
      table_last_update: '心跳報告時間',
      table_last_heartbeat: '上次心跳已经经过'
    },    
    menu: {
      gb: '英',
      hk: '中',
      jp: '日'
    },
    about: {
      timeline_add_reservation: '房東獲取客人預約信息後，使用手動，批量或者自動等方式在GoCheckIn登记预约信息。',
      timeline_upload_id: '入住前客人獲得GoCheckIn帳號信息。在GoCheckIn網站登錄後，拍攝上傳入住成員的有效身分證件照片以及其他以及其他法規規定的信息。',
      timeline_verify_id: '房東確認身分證件照片是否清晰可用，以及其他信息是否正確有效。',
      timeline_guidebook: '獲得房東確認所有成員的身分證件後，客人可以從GoCheckIn網站獲取入住導航等信息。',
      timeline_match_id: '客人到達住宿地點，所有成員都拍攝了個人照片，以進行與证件照之間的人臉對比身分识别。',
      timeline_host_verify: '以下特殊情況之外，無需房東人工干預即可完成自動入住登記：1.客人到達住宿地點之前，房東未能確認所有入住客人成員的有效身分證件。2.在住宿地點進行身分識別時，客人從新拍攝了部分或所有成員的的身分證件。3.客人有分批入住登記或成員數量臨時變化等其他特殊情況。',
      timeline_key: '身分識別順利完成，客人獲取鑰匙等信息，成功登記入住。',
      timeline_guest_title_remote: '客人所執行的操作（開始行程之前）',
      timeline_guest_title_site: '客人所執行的操作（到達入住地點時）',
      timeline_host_title: '房東的操作',
    }
  },
  jp: {
    listing: {
      title: '物件一覧',
      btn_new: '物件登録',
      btn_edit: '物件編集',
      btn_save: '保存',
      btn_cancel: 'キャンセル',
      table_search: '検索',
      table_edit: '編集',
      table_listing_name: '物件名',
      table_listing_id: '物件ID',
      table_manual_check: '身分確認',
      table_guide: '物件ガイド',
      table_payment: '支払ステータス',
      table_registerd_on: '登録日',
      table_goto_reservations: '予約一覧',
      table_goto_scanners: '顔認証スキャナ一覧'
    },
    reservation: {
      title: '予約一覧',
      btn_new: '登録',
      btn_import: 'バッチ登録',      
      btn_edit: '予約編集',
      btn_save: '保存',
      btn_cancel: 'キャンセル',
      msg_guest_name: '来訪者の代表者氏名が必要です。',
      msg_phone_no: '連絡先の電話番号が必要です。',
      msg_member_count: '来訪者の人数が必要です。',
      msg_checkin_date: 'チェックイン日付が必要です。',
      msg_checkout_date: 'チェックアウト日付が必要です。',
      table_search: '検索',
      table_edit: '編集',
      table_reservation_code: '予約コード',
      table_booking_id: '外部予約ID',
      table_guest: '来訪者の代表者',
      table_phone_no: '電話番号',
      table_email: 'メール',
      table_member_count: '来訪者人数',
      table_checkin_date: 'チェックイン日付',
      table_checkout_date: 'チェックアウト日付',
      table_key_info: 'カギ情報',
      table_deploy: '来訪者情報の展開',
      table_last_request: '前回成功した展開の日時',
      table_remove: '展開のクリア',
      table_goto_members: '来訪者詳細確認',
      table_smart_lock: 'Smart Lock',
      table_smart_lock_keypad: 'Smart Lock Keypad'
    },
    member: {
      btn_export: '来訪者情報のCSV出力',
      btn_deploy: '顔認証スキャナーへ展開',
      btn_remove: '展開のクリア',      
      title: '来訪者一覧',
      title_sub1: '来訪者ID',
      title_sub2: '来訪者写真',
      title_sub3: '来訪者詳細情報',
      drop_id: '身分証明書画像をこちらへ',
      drop_face: '顔写真をこちらへ',
      label_fullname: '氏名',
      label_nationality: '国籍',
      label_id_no: '身分証明書番号',
      label_phone_no: '電話番号',
      label_birth_date: '生年月日',
      label_gender: '性別',
      label_occupation: '職業',
      label_address: '住所',
      label_room_number: '部屋番号',
      label_key_info: '部屋カギ',
      label_prev_place: '前泊地',
      label_next_dest: '行先地',
      label_guest: '来訪者',
      label_valid_id: 'ID画像が有効',
      label_id_matches: 'IDと顔写真がマッチング',
      label_check_detail: '詳細確認', 
    },
    scanner: {
      title: '顔認証スキャナ',
      table_search: '検索',
      table_listing_id: '物件ID',
      table_listing_name: '物件名',
      table_core: 'ゲートウェイ',
      table_terminal_name: '顔認証スキャナ',
      table_terminal_key: 'スキャナ・シリアルNo',
      table_room_code: '配置部屋番号',
      table_ip: 'IPアドレス',
      table_latitude: '緯度',
      table_longitude: '経度',
      table_last_update: 'ハートビート日時',
      table_last_heartbeat: 'ハートビート経過'
    }, 
    menu: {
      gb: '英',
      hk: '漢',
      jp: '和'
    },
    about: {
      timeline_add_reservation: '宿泊予約確定後、ホストが手動、自動、またはインポートの方式でGoCheckInに情報を登録します。',
      timeline_upload_id: 'ゲストがGoCheckInサイトのログイン情報を入手しました。ログイン後，各宿泊メンバーの身分証明書の写し、およびその他法令に基づいた情報を登録します。',
      timeline_verify_id: 'ホストが身分証明書とその他の情報を確認します。',
      timeline_guidebook: 'ホストによる身分証明書の確認が完了しましたら、ゲストがGoCheckInサイトで宿泊施設のガイドブック情報を取得できます。',
      timeline_match_id: '宿泊施設に到着後、各メンバーが顔写真を取り，顔認証による身分確認を行います。',
      timeline_host_verify: '以下の特殊状況以外に、無人チェックイン（セルフチェックイン）がスムーズに完成できます。１．チェックインまでゲストのすべての身分証明書をホストが確認できていない場合。２．宿泊施設にて顔写真撮影の際，身分証明書の写しが変更された場合。３．宿泊メンバーが別々のタイミングでチェックインする場合や、突然な人数変更の場合等特殊状況',
      timeline_key: '顔認証による身分確認でき、ゲストが入居のカギ情報を取得し、セルフチェックイン完了します。',
      timeline_guest_title_remote: '宿泊者（ゲスト）のアクション（旅を始める前）',
      timeline_guest_title_site: '宿泊者（ゲスト）のアクション（施設に到着時）',
      timeline_host_title: '宿泊施設管理者（ホスト）側のオペレーション',
    }
  }
}

export default messages