import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
// import Cookies from 'js-cookie'

import session from './modules/session'
import host from './modules/host'
import scanner from './modules/scanner'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersistence({
  key: 'gocheckin-vuex', // The key to store the state on in the storage provider.
  storage: window.sessionStorage, // or window.sessionStorage or localForage
})

export default new Vuex.Store({
  modules: {
    session,
    host,
    scanner
  },
  state: {},
  mutations: {},
  actions: {},
  plugins: [vuexLocalStorage.plugin]
})
