import Amplify, { API, graphqlOperation } from "aws-amplify"
import * as gql from './appsync-gql'

export default {
  
  async refreshProperty (hostId, propertyCode) {
    return await API.graphql(
        graphqlOperation( gql.refreshProperty, { hostId: hostId, propertyCode: propertyCode })
    ).catch(e => {
      console.error('refreshProperty error:', e)
      return e
    })
  },

  async getPropertyLocks (hostPropertyCode) {
    return await API.graphql(
        graphqlOperation( gql.getPropertyLocks, { hostPropertyCode: hostPropertyCode })
    ).catch(e => {
      console.error('getPropertyLocks error:', e)
      return e
    })
  },
  async getListings (hostId) {
    return await API.graphql(
      graphqlOperation(gql.getListings, { hostId: hostId })
    ).catch(e => {
      console.error('getListings error:', e)
      return e
    })
  },
  async createListing (input) {
    input.lastUpdateOn = (new Date).toISOString()

    // const gqlOpr = graphqlOperation(gql.createListing, { input })
    // console.log(gqlOpr, 'gqlOpr')

    return await API.graphql(
      graphqlOperation(gql.createListing, { input })
    ).catch(e => {
      console.error('createListing error:', e)
      return e
    })
  },
  async updateListing (input) {
    const expectedLastUpdateOn = input.lastUpdateOn
    input.lastUpdateOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.updateListing, { input, expectedLastUpdateOn })
    ).catch(e => {
      console.error('updateListing error:', e)
      return e
    })
  },
  async importListings (input) {

    return await API.graphql(
      graphqlOperation(gql.importListings, { input })
    ).catch(e => {
      console.error('importListings error:', e)
      return e
    })
  },
  async getReservations (listingId) {
    return await API.graphql(
      graphqlOperation(gql.getReservations, { listingId: listingId })
    ).catch(e => {
      console.error('getReservations error:', e)
      return e
    })
  },
  async getReservation (reservationCode, listingId) {
    return await API.graphql(
      graphqlOperation(gql.getReservation, { reservationCode: reservationCode, listingId: listingId })
    ).catch(e => {
      console.error('getReservation error:', e)
      return e
    })
  },
  onUpdateReservation (hostId, listingId) {
    return API.graphql(graphqlOperation(gql.onUpdateReservation, { hostId: hostId, listingId: listingId }))
  },
  async updateReservationStatus (input) {
    console.log('appsync.updateReservationStatus input', input)

    const expectedLastUpdateOn = input.lastUpdateOn
    input.lastUpdateOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.updateReservationStatus, { input, expectedLastUpdateOn })
    ).catch(e => {
      console.error('updateReservationStatus error:', e)
      return e
    })
  },
  async removeReservation (input) {

    return await API.graphql(
      graphqlOperation(gql.removeReservation, { input })
    ).catch(e => {
      console.error('removeReservation error:', e)
      return e
    })
  },
  onRemoveReservation (hostId) {
    return API.graphql(graphqlOperation(gql.onRemoveReservation, { hostId: hostId }))
  },
  async generateReservation (input) {

    return await API.graphql(
      graphqlOperation(gql.generateReservation, { input })
    ).catch(e => {
      console.error('generateReservation error:', e)
      return e
    })
  },
  async updateIdVerified (input) {
    return await API.graphql(
        graphqlOperation(gql.updateIdVerified, { input })
      ).catch(e => {
        console.error('updateIdVerified', e)
        return e
      })
  },
  async notifyGuest (input, messageType) {
    return await API.graphql(
      graphqlOperation(gql.notifyGuest, { input, messageType })
    ).catch(e => {
      console.error('notifyGuest error:', e)
      return e
    })
  },
  async getMembers (reservationCode) {
    return await API.graphql(
        graphqlOperation(gql.getMembers, { reservationCode: reservationCode })
    ).catch(e => {
      console.error('getMembers error:', e)
      return e
    })
  },
  async createMember (input) {
    input.lastUpdateOn = (new Date).toISOString()

    // const gqlOpr = graphqlOperation(gql.createListing, { input })
    // console.log(gqlOpr, 'gqlOpr')

    return await API.graphql(
      graphqlOperation(gql.createMember, { input })
    ).catch(e => {
      console.error('createMember error:', e)
      return e
    })
  },
  async updateMember (input) {
    const expectedLastUpdateOn = input.lastUpdateOn
    input.lastUpdateOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.updateMember, { input, expectedLastUpdateOn })
    ).catch(e => {
      console.error('updateMember error:', e)
      return e
    })
  },
  // onCreateNotification (hostId) {
  //   return API.graphql(graphqlOperation(gql.onCreateNotification, { hostId: hostId }))
  // },
  // async deleteNotification (input) {
  //   return await API.graphql(
  //     graphqlOperation(gql.deleteNotification, { input })
  //   ).catch(e => {
  //     console.error('getReservations error:', e)
  //     return e
  //   })
  // },
  // async getNotifications (hostId) {
  //   return await API.graphql(
  //     graphqlOperation(gql.getNotifications, { hostId: hostId })
  //   ).catch(e => {
  //     console.error('getNotifications error:', e)
  //     return e
  //   })
  // },
  onCreateScanner (hostId) {
    return API.graphql(graphqlOperation(gql.onCreateScanner, { hostId: hostId }))
  },
  async getScanners (hostPropertyCode) {
    console.log('getScanners hostPropertyCode', hostPropertyCode)
    
    return await API.graphql(
        graphqlOperation( gql.getScanners, { hostPropertyCode: hostPropertyCode })
    ).catch(e => {
      console.error('getScanners error:', e)
      return e
    })
  },
  onCreateScanRecord ({hostId, terminalKey}) {
    return API.graphql(graphqlOperation(gql.onCreateScanRecord, { hostId: hostId, terminalKey: terminalKey }))
  },
  async getScanRecords (terminalKey) {
    return await API.graphql(
        graphqlOperation( gql.getScanRecords, { terminalKey: terminalKey })
    ).catch(e => {
      console.error('getScanRecords error:', e)
      return e
    })
  },
  async getCalendars (params) {
    console.log('getCalendars params', params)

    return await API.graphql(
      graphqlOperation( gql.getCalendars, params)
    ).catch(e => {
      console.error('getCalendars error:', e)
      return e
    })
  },
  async getHost (hostId) {
    console.log('getHost', hostId)
    
    return await API.graphql(
      graphqlOperation(gql.getHost, { hostId: hostId })
    ).catch(e => {
      console.error('getHost error:', e)
      return e
    })
  },
  async createHost (input) {
    console.log('createHost', input)

    input.lastUpdateOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.createHost, { input })
    ).catch(e => {
      console.error('createHost error:', e)
      return e
    })
  },
  async updateHost (input) {
    const expectedLastUpdateOn = input.lastUpdateOn
    input.lastUpdateOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.updateHost, { input, expectedLastUpdateOn })
    ).catch(e => {
      console.error('updateHost', e)
      return e
    })
  },
  async verifyChannel (inviteCode, hostId) {
    return await API.graphql(
      graphqlOperation(gql.verifyChannel, { inviteCode, hostId })
    ).catch(e => {
      console.error('verifyChannel', e)
      return e
    })
  },
  async dispatchScannerDeployment (input) {
    console.log('dispatchScannerDeployment', input)

    input.lastRequestOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.dispatchScannerDeployment, { input })
    ).catch(e => {
      console.error('dispatchScannerDeployment', e)
      return e
    })
  },
  async resetScannerDeployment (input) {
    console.log('resetScannerDeployment', input)

    input.lastRequestOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.resetScannerDeployment, { input })
    ).catch(e => {
      console.error('resetScannerDeployment', e)
      return e
    })
  },
  async refreshDevices (input) {
    console.log('refreshDevices', input)

    return await API.graphql(
      graphqlOperation(gql.refreshDevices, { input })
    ).catch(e => {
      console.error('refreshDevices', e)
      return e
    })
  },
  /*
  async getOfflinePassword (input) {
    console.log('getOfflinePassword', input)

    return await API.graphql(
      graphqlOperation(gql.getOfflinePassword, { input })
    ).catch(e => {
      console.error('getOfflinePassword', e)
      return e
    })
  },
  async getTempPassword (input) {
    console.log('getTempPassword', input)

    return await API.graphql(
      graphqlOperation(gql.getTempPassword, { input })
    ).catch(e => {
      console.error('getTempPassword', e)
      return e
    })
  },
*/
  async updateHostPms (hostId, input) {
    console.log('updateHostPms', {hostId, input})

    return await API.graphql(
      graphqlOperation(gql.updateHostPms, { hostId, input })
    ).catch(e => {
      console.error('updateHostPms', e)
      return e
    })
  },  
  async initHostPms (input) {
    console.log('initHostPms', input)

    return await API.graphql(
      graphqlOperation(gql.initHostPms, { input })
    ).catch(e => {
      console.error('initHostPms', e)
      return e
    })
  },
  async getPmsList () {
    console.log('getPmsList')

    return await API.graphql(
      graphqlOperation(gql.getPmsList)
    ).catch(e => {
      console.error('getPmsList', e)
      return e
    })
  },
  async getHostPms (hostId) {
    console.log('getHostPms', hostId)

    return await API.graphql(
      graphqlOperation(gql.getHostPms, {hostId})
    ).catch(e => {
      console.error('getHostPms', e)
      return e
    })
  }
}